<template>
  <v-app>

    <div class="login fh">

      <v-container>

        <v-row>
          <v-col>
            <div class="circle-logo text-center pt-12">
              <logo-img></logo-img>
            </div>
            <div class="logo-img">
              <div class="text-center">
                <logo class="logo" />
                <div class="caption primary--text">
                  Manager
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="pt-3">
          <v-col>
            <v-form
              class="form"
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :error-messages="error.email"
                :disabled="loading"
                label="E-mail"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="passwordRules"
                :error-messages="error.password"
                :disabled="loading"
                label="Password"
                type="password"
                required
              ></v-text-field>
              
              <div class="otp" v-if="has_otp">
                
                <v-text-field
                v-model="otpCode"
                :rules="otpCodeRules"
                :disabled="loading"
                :error-messages="error.otp"
                label="Enter OTP Code"
                type="text"
                required
                hint="An OTP Code has been sent to your email"
                persistent-hint

                >
                </v-text-field>

                <v-btn
                block
                type="submit"
                :disabled="!valid||loading"
                :loading="loading"
                color="primary"
                class="mr-4 mt-4"
                @click.prevent="onSubmitWithOtp"

                >
                Submit
                </v-btn>
              </div>
              <recaptcha
                v-if="!has_otp"
                :data-sitekey="recaptcha_key"
                :data-callback="recaptchaCallback"
                :data-validate="validate"
                :data-size="'visible'"
                :data-btn-disabled="!valid || loading"
                :loading="loading"
              >
              Login
              </recaptcha>
<!--               <v-btn
                v-if="!has_otp"
                block
                type="submit"
                :disabled="!valid || loading"
                :loading="loading"
                color="primary"
                class="mr-4 mt-4"
                @click.prevent="onSubmit"
              >
                Login
              </v-btn> -->

              <p class="overline mt-6">
                Don't have account? <router-link :to="{name: 'Register'}">Create account</router-link>
                <br>
                Forgot password?
                <router-link :to="{name: 'RequestPasswordReset'}">Reset password</router-link>
                <br>
                <router-link :to="{name: 'ResendConfirmationInstructions'}">
                Didn't receive confirmation instructions?
                </router-link>
              </p>

            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>

import Logo from '@/components/Logo'
import LogoImg from '@/components/LogoImg'
import { login, sendOtp } from '@/services/http/auth'
import Recaptcha from '@/components/Recaptcha';

export default {
  components: {
    Logo,
    LogoImg,
    Recaptcha
  },
  data: () => ({
    recaptcha_key: process.env.VUE_APP_RECAPTCHA_KEY_2,
    loading: false,
    has_otp: false,
    user_id: '',
    error: {},
    valid: true,
    otpCode: '',
    password: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    otpCodeRules: [
      v => !!v || 'OTP Code is required'
    ]
  }),
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    recaptchaCallback(token) {
      if (token) {
        this.onSubmit(token);
      }else{
        alert('Please check captcha to verify you are not a robot');
      }
    },

    async onSubmit(captcha_token) {
      this.loading = true
      try {
        const values = {
          email: this.email,
          password: this.password,
          captcha_token: captcha_token
        }
        const data = await login(values);
        if(data && data.has_otp){
          this.has_otp = data.has_otp
          this.user_id = data.user_id
          this.loading = false
        }else{
          this.$router.push({name: 'Home'})
        }   
      } catch (e) {
        window.grecaptcha.reset()
        this.loading = false
        this.error = e
      }
    },

    async onSubmitWithOtp(){
      try{
        this.loading = true
        await sendOtp(this.user_id,this.otpCode)
        this.$router.push({name: 'Home'})
      }catch(e){
        this.loading = false
        this.error = e
      }
    }
  },
  watch: {
    email(v) {
      if (v) {
        this.error = {}
        this.has_otp = false
      }
    },
    password(v) {
      if (v) {
        this.error = {}
        this.has_otp = false
      }
    },
    otpCode(v){
      if(v) this.error = {}
    }
  }
}

</script>


